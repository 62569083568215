<template>
    <div class="container">
        <h1 class="title">アンケート -- {{ live.Title }}</h1>
        <h2 class="subtitle">({{ live.UUID }})</h2>

        <p class="update-msg">
            [Update 2022/11/14]
            「専門科」、「年齢」列を「都道府県」列の後ろに追加しました。
        </p>
        <p class="update-msg">
            [Update 2022/09/14]
            「事前アンケート」に「予約済み」列を追加しました。
        </p>
        <p class="update-msg">
            [Update 2021/09/29]
            「回答ラベル」列を追加しました。質問内容によっては全て空欄になることもあるので、その場合はお手数ですが列削除などでご対応お願いします。
        </p>

        <b-loading is-full-page v-model="isLoading"></b-loading>
        <div v-if="!isLoading">
            <div class="buttons">
                <b-button @click="reload">Reload</b-button>

                <b-dropdown aria-role="list">
                    <template #trigger>
                        <b-button label="グラフリンク" type="is-primary" icon-right="angle-down" />
                    </template>
                    <template v-for="surveyAnswers in surveysAnswers">
                        <b-dropdown-item has-link aria-role="listitem" :key="surveyAnswers.SurveyUuid">
                            <a
                                :href="'https://www.e-casebook.com/survey/' + surveyAnswers.SurveyUuid + '/result'"
                                target="_blank"
                            >
                                <b-icon icon="link"></b-icon>
                                {{ surveyAnswers.SurveyTitle }}({{ surveyAnswers.SurveyUuid }})
                            </a>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>

                <b-dropdown aria-role="list">
                    <template #trigger>
                        <b-button label="ダウンロード" type="is-success" icon-right="angle-down" />
                    </template>
                    <template v-for="surveyAnswers in surveysAnswers">
                        <b-dropdown-item aria-role="listitem" :key="surveyAnswers.SurveyUuid">
                            <b-icon icon="download"></b-icon>
                            <vue-excel-xlsx
                                :data="surveyAnswers.Answers"
                                :columns="surveyAnswers.SurveyTitle.indexOf('事前アンケート') !== -1 ? columns : columns.filter(function(column) { return column.label !== '予約済み' })"
                                :filename="`${surveyAnswers.SurveyTitle}_${live.Title}_${formatDateYMD(
                                    live.ScheduledStartDateTime
                                )}`"
                                :sheetname="surveyAnswers.SurveyTitle.length > 31 ? surveyAnswers.SurveyTitle.slice(0, 28) + '...' : surveyAnswers.SurveyTitle"
                            >
                                {{ surveyAnswers.SurveyTitle }}({{ surveyAnswers.SurveyUuid }})_{{live.Title}}_{{formatDateYMD(live.ScheduledStartDateTime)}}
                            </vue-excel-xlsx>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </div>

            <b-tabs v-model="activeTab" v-if="surveysAnswers.length > 0">
                <template v-for="surveyAnswers in surveysAnswers">
                    <b-tab-item
                        :label="surveyAnswers.SurveyTitle + '(' + surveyAnswers.SurveyUuid + ')'"
                        :key="surveyAnswers.SurveyUuid"
                        :value="surveyAnswers.SurveyUuid"
                    >
                        <b-table :data="surveyAnswers.Answers" striped hoverable narrowed>
                            <b-table-column field="Id" label="ユーザID" numeric v-slot="props">
                                {{ props.row.Id }}
                            </b-table-column>
                            <b-table-column field="Name" label="氏名" v-slot="props">
                                {{ props.row.Name }}
                            </b-table-column>
                            <b-table-column field="Hospital" label="所属施設" v-slot="props">
                                {{ props.row.Hospital }}
                            </b-table-column>
                            <b-table-column field="Prefecture" label="都道府県" v-slot="props">
                                {{ props.row.Prefecture }}
                            </b-table-column>
                            <b-table-column field="Speciality" label="専門科" v-slot="props">
                                {{ props.row.Speciality }}
                            </b-table-column>
                            <b-table-column field="Age" label="年齢" v-slot="props">
                                {{ props.row.Age }}
                            </b-table-column>
                            <b-table-column field="QuestionId" label="質問ID" numeric v-slot="props">
                                {{ props.row.QuestionId }}
                            </b-table-column>
                            <b-table-column field="QuestionContent" label="質問内容" v-slot="props">
                                {{ props.row.Content }}
                            </b-table-column>
                            <b-table-column field="AnswerId" label="回答ID" numeric v-slot="props">
                                {{ props.row.ChoiceId }}
                            </b-table-column>
                            <b-table-column field="AnswerLabel" label="回答ラベル" numeric v-slot="props">
                                {{ props.row.ChoiceLabel }}
                            </b-table-column>
                            <b-table-column field="AnswerContent" label="回答内容" v-slot="props">
                                {{ props.row.ChoiceContent }}
                            </b-table-column>
                            <b-table-column field="AnswerCreated" label="回答日時" v-slot="props">
                                {{ props.row.Created | formatDate }}
                            </b-table-column>
                            <b-table-column field="Reserved" label="予約済み" v-slot="props" v-if="surveyAnswers.SurveyTitle.indexOf('事前アンケート') !== -1">
                                {{ props.row.Reserved }}
                            </b-table-column>
                        </b-table>
                    </b-tab-item>
                </template>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import formatterMixin from "../mixins/formatter";

export default {
    name: "Survey",
    mixins: [formatterMixin],
    data: () => ({
        activeTab: 0,
        isLoading: true,
        columns: [
            {
                label: "ユーザID",
                field: "Id",
            },
            {
                label: "氏名",
                field: "Name",
            },
            {
                label: "所属施設",
                field: "Hospital",
            },
            {
                label: "都道府県",
                field: "Prefecture",
            },
            {
                label: "専門科",
                field: "Speciality",
            },
            {
                label: "年齢",
                field: "Age",
            },
            {
                label: "質問ID",
                field: "QuestionId",
            },
            {
                label: "質問内容",
                field: "Content",
            },
            {
                label: "回答ID",
                field: "ChoiceId",
            },
            {
                label: "回答ラベル",
                field: "ChoiceLabel",
            },
            {
                label: "回答内容",
                field: "ChoiceContent",
            },
            {
                label: "回答日時",
                field: "Created",
            },
            {
                label: "予約済み",
                field: "Reserved",
            },
        ],
    }),
    async created() {
        this.isLoading = true;
        await this.$store.dispatch("getLive", this.$route.params.liveId);
        await this.$store.dispatch("getSurveysAnswers", this.$route.params.liveId);
        this.isLoading = false;
    },
    watch: {
        $route: "reload",
    },
    computed: {
        surveysAnswers() {
            let surveyAnswers = this.$store.getters.surveysAnswers(this.$route.params.liveId);
            if (surveyAnswers.length) {
                surveyAnswers.forEach((item) =>
                    item.Answers.map((answer) => {
                        answer.Created = answer.Created.replace("T", " ").replace("Z", "");
                        return answer;
                    })
                );
            }
            return surveyAnswers;
        },
        live() {
            return this.$store.getters.live();
        },
    },
    methods: {
        async reload() {
            this.isLoading = true;
            await this.$store.dispatch("getLive", this.$route.params.liveId);
            await this.$store.dispatch("getSurveysAnswers", this.$route.params.liveId);
            this.isLoading = false;
        },
    },
};
</script>

<style scoped>
</style>
